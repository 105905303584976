import { PageElement, html, css, unsafeHTML } from 'Elements';
import { Session, Sleep } from 'Utils';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';

import './notification.js';


class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        m-icon {
          padding-right:10px;
          font-size:40px;
        }

        sl-tree {
          --indent-guide-width: 1px;
          min-width:220px;
          padding:0px;
          margin:0px;
        }

        sl-tree-item::part(label) {
          font-size:16px;
          font-family:Calibri;
          color:var(--text-color);
        }

        .mycontainer {
          display:flex;
          margin-top:20px;
          margin-bottom:20px;
          min-height:50vh;
        }

        .tabcontent {
          width:100%;
        }

        sl-dropdown {
          position: absolute;
          right: 0px;
          cursor:pointer;
          text-transform:initial;
          font-size:10px;
        }

        sl-menu-item::part(label) {
          font-size:13px;
        }

        sl-menu-item::part(checked-icon) {
          width:5px;
        }

        m-icon[name="lock"] {
          font-size:20px;
          position:absolute;
          right:0px;
        }

        .hidden {
          display:none;
        }

        sl-breadcrumb {
          height:40px;
        }

        sl-breadcrumb-item::part(label) {
          font-size:2em;
          font-family:Calibri;
          text-transform:uppercase;
          
        }

        @media (max-width: 670px) {
          sl-tree {
            display:none;
          }
        }

        @media (min-width: 670px) {
          sl-dropdown {
            display:none;
          }
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            menu_notification:'Notifications RT',
          }
        },
        french:{
          translation: {
            menu_notification:'Notifications RT',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.handlePopState = this.handlePopState.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('popstate', this.handlePopState);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState() {
    const hash = window.location.hash.replace(/^#/, '');
    this.updateContent(hash);
  }

  async firstUpdated() {
    super.firstUpdated();
    this.sessionData = await Session.get();
    await Sleep(100);
    this.initTree();
  }

  initTree() {
    const hash = window.location.hash ? window.location.hash.toString().replace(/^#/,'') : 'notification';
    const qs = `sl-tree-item[value="${hash}"]`;
    const treeItem = this.shadowRoot.querySelector(qs);
    if (treeItem) {
      treeItem.selected = true;
      if (treeItem.parentNode.tagName === 'SL-TREE-ITEM') {
        treeItem.parentNode.expanded = true;
      }
    }
    this.updateContent(hash);
  }

  toggleForm(form, readonly) {
    const elements = getFormControls(form);
    for (let i = 0, len = elements.length; i < len; ++i) {
      //elements[i].readonly = readonly;
      elements[i].disabled = readonly;
    }
  }

  handleTreeItemClick(ev) {
    //const itemValue = ev.target.getAttribute('value');
    const firstSubMenuItem = ev.target.querySelector('sl-tree-item');
    if (firstSubMenuItem) {
      ev.preventDefault();
      ev.stopPropagation();
      if (!ev.target.expanded) {
        ev.target.expanded = true;
      } else {
        ev.target.expanded = false;
        return;
      }
      const currentItemSelected = ev.target.parentNode.querySelector('sl-tree-item[selected]');
      if (currentItemSelected) currentItemSelected.selected = false;
      firstSubMenuItem.selected = true;
      this.updateContent(firstSubMenuItem.getAttribute('value'));
      return;
    }

    this.updateContent(ev.target.getAttribute('value'));
  }

  updateContent(hash) {
    if (!hash) return;
    hash = hash.replace(/^#/, '');
    window.location.hash = hash;
    if (this.currentTabContent) {
      this.currentTabContent.hide();
    }

    const newTab = this.shadowRoot.querySelector(`#${hash}`);
    if (newTab && newTab.show) {
      newTab.show();
      this.currentTabContent = newTab;
    }
  }


  render() {

    const menu = [
      { value: 'notification' },
      /*
      { value: 'templates', sub: [
        { value: 'sms' },
        { value: 'ntfy' },
        { value: 'teams' }
        ]
      }
      */
    ]

    const menuCopy = JSON.parse(JSON.stringify(menu));

    return html`
      <div class="content_page">
          <div class="item">
            <h3>
              <m-icon name="pest_control"></m-icon>
              <sl-breadcrumb>
                <sl-breadcrumb-item><a-route href="/private">Mon espace</a-route></sl-breadcrumb-item>
                <sl-breadcrumb-item>Administration<sl-icon slot="suffix" name="shield-lock"></sl-icon></sl-breadcrumb-item>
              </sl-breadcrumb>
              <sl-dropdown>
                <m-icon slot="trigger" name="menu"></m-icon>
                <sl-menu @click="${this.handleTreeItemClick}">
                ${menu.map(item => html`
                  <sl-menu-item value="${item.value}" expanded>
                    ${unsafeHTML(this._tl(`menu_${item.value}`))}
                    ${item.sub ? html`
                      <sl-menu slot="submenu">
                        ${item.sub.map(subItem => unsafeHTML(`
                          <sl-menu-item value="${subItem.value}">${subItem.label}</sl-menu-item>
                        `))}
                      </sl-menu>
                    ` : ''}
                  </sl-menu-item>
                `)}
              </sl-menu>
              </sl-dropdown>
            </h3>
            <hr-custom></hr-custom>

            <div class="mycontainer">
              
              <sl-tree @click="${this.handleTreeItemClick}">
                ${menuCopy.map(item => html`
                <sl-tree-item value="${item.value}" expanded>${unsafeHTML(this._tl(`menu_${item.value}`))}
                ${item.sub ? item.sub.map(subItem => unsafeHTML(`
                  <sl-tree-item value="${subItem.value}">${this._tl(`menu_${subItem.value}`)}</sl-tree-item>
                `)) : ''}
                </sl-tree-item>
              `)}
              </sl-tree>

              <div class="tabcontent">
                <rt-notification id="notification"></rt-notification>
                <!--
                <form-user-account-security-auth id="security_auth"></form-user-account-security-auth>
                <form-user-account-security-activity id="security_activity"></form-user-account-security-activity>
                <form-user-account-session-infos id="session"></form-user-account-session-infos>
                -->
              </div>

            </div>
          </div>
      </div>
    `
  }
}

customElements.define('page-request-tracker', Page);