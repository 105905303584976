import { css } from 'lit-element';

export default css`
:host {
  display:none;
}

form {
  border-left:1px solid var(--sl-color-gray-200);
  padding-right:20px;
  padding-left:20px;
  flex:1;
}

h2 {
  margin-top:5px;
  color: var(--nsys-blue3);
  text-transform: uppercase;
  font-family: Calibri;
  font-size:1.1em;
  line-height:1.6em;
}

h3 {
  border-bottom:1px solid var(--sl-color-gray-200);
}
`;
