import { PageElement, unsafeHTML, html, css } from 'Elements';
import { Session } from 'Utils';

import './user';
import './admin';
import './soc';
import './doc';
import './dev';
import './probe';
import './cus';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }

        box-styled[variant="warning"] {
          font-size:0.8rem;
          text-transform:initial !important;
          padding:0px !important;
          height:initial !important;
          overflow:hidden;
          opacity:0;
          transition:opacity 0.5s;
          margin-bottom:20px;
        }

        box-styled[variant="warning"] div {
          height:initial !important;
          padding:0px;
          overflow:hidden;
        }

        box-styled[variant="warning"].expanded {
          opacity:1;
        }

        a-route {
          color:var(--sl-color-primary-800);
          font-weight:bold;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            title: 'My Space',
            account: 'My Account',
            admin: 'Administration',
            soc: 'SOC space',
            dev: 'Developer space',
            investigation:'Investigation space',
            doc: 'Documentations',
            com:'Communications',
            low_auth: 'You have authenticated using your email and password. <a-route href="/private/user/account#security_auth">Activate two-factor authentication</a-route> for more security.',
            my_tickets: 'My tickets',
            my_draft: 'My drafts'
          },
        },
        french: {
          translation: {
            title: 'Mon espace',
            account: 'Mon compte',
            admin: 'Administration',
            soc: 'Espace SOC',
            dev: 'Espace développeur',
            investigation:'Espace investigations',
            com:'Espace communication',
            doc: 'Documentations',
            low_auth: 'Vous vous êtes authentifié à l\'aide de votre email et mot de passe. <a-route href="/private/user/account#security_auth">Activez la double authentification</a-route> pour plus de sécurité.',
            my_tickets: 'Mes tickets',
            my_draft: 'Mes brouillons'
          }
        }
      }
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }

  renderAuthLevel() {
    if (Session.authMethod === 'form') {
      setTimeout(() => {
        this.qs('box-styled[variant="warning"]').classList.add('expanded');
      }, 10);
      return html`<box-styled slot="right" micon="warning" variant="warning"><div>${unsafeHTML(this._tl('low_auth'))}</div></box-styled>`;
    }
  }

  renderCus() {
    if (!Session.isCus()) return;
    return html`
      <section-header size="medium" micon="security">Evénements de sécurité</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="bar_chart_4_bars" aroute="./cus/tickets/dashboard">Tableau de bord</box-styled>
        <box-styled layout="${this.layout}" micon="event_note" aroute="./cus/tickets">${this._tl('my_tickets')}</box-styled>
        <box-styled layout="${this.layout}" micon="edit_square" aroute="./cus/tickets/draft">${this._tl('my_draft')}</box-styled>
      </div>
      <br/><br/>
      <section-header size="medium" micon="construction">Autres</section-header>
    `
  }
  
  render() {

    return html`
      <section-header img="assets/img/monespace.png">
        ${this._tl('title')}
      </section-header>
      <br/>
      ${this.renderAuthLevel()}

      ${this.renderCus()}

      <div class="grid">
        <box-styled layout="${this.layout}" micon="account_circle" aroute="/private/user/account">${this._tl('account')}</box-styled>
        ${Session.isBoss() ? html`<box-styled layout="${this.layout}" micon="admin_panel_settings" aroute="/private/admin">${this._tl('admin')}</box-styled>` : '' }
        ${Session.isSoc() ? html`<box-styled layout="${this.layout}" micon="shield" aroute="/private/soc">${this._tl('soc')}</box-styled>` : '' }
        ${Session.isSoc() ? html`<box-styled layout="${this.layout}" micon="policy_alert" aroute="/private/probe">${this._tl('investigation')}</box-styled>` : '' }
        ${Session.isCom() ? html`<box-styled layout="${this.layout}" micon="text_to_speech" aroute="/private/com">${this._tl('com')}</box-styled>` : '' }
        <box-styled layout="${this.layout}" micon="quiz" aroute="/private/doc">${this._tl('doc')}</box-styled>
        ${Session.isBoss() ? html`<box-styled layout="${this.layout}" micon="developer_board" aroute="/private/dev">${this._tl('dev')}</box-styled>` : '' }
      </div>
      <br/><br/>
    `;
  }

}

customElements.define('page-user-home', Page);